import cx from "classnames";
import get from "lodash/get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PaymentMethodsProps } from "src/api/auth";
import { ReactComponent as PlusCircle } from "src/assets/icons/plus-outline-circle-icon.svg";
import Button from "src/components/Button";
import PaymentForm, { PaymentHandleProps } from "src/components/PaymentForm";
import Text from "src/components/Text";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import { useIndexData } from "src/pages/Watch/selectorData";
import { addPaymentMethods, postPaymentDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useUserActions } from "src/utils/useActions";
import UnsubscribeModal from "../unsubscribeModal";
import Complete from "./Complete";
import Confirm from "./Confirm";
import classes from "./PaymentInformation.module.scss";

type Props = {
  selectedPlans: PlanProps;
  onClose: () => void;
};

export type NewPaymentDetailsProps = {
  payment_id: string;
  plan_id: number;
  name_on_card: string;
  last4: string;
};

const PaymentInformation: React.FC<Props> = ({ selectedPlans, onClose }) => {
  const { changeSubscription } = useUserActions();
  const {
    data: paymentMethods,
  }: { data: PaymentMethodsProps[]; loading: boolean } = useReducerData(
    "auth",
    "paymentMethods",
    {
      data: [],
    }
  );
  const userProfile = useUserProfile() as {
    data: { plans: PlanProps[] };
  };
  const { subscription } = useIndexData();

  const actions = useStoreActions({
    postPaymentDetails,
    addPaymentMethods,
  });
  const planId = get(userProfile, "subscription.plan_id", null);
  const isCancelled = get(userProfile, "subscription.is_cancelled", 0);
  const subscriptionStatus = get(userProfile, "subscription.status");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] =
    useState<NewPaymentDetailsProps>();

  const [addNewPaymentFlow, setAddNewPaymentFlow] = useState(false);
  const [paymentFlowSteps, setPaymentFlowSteps] = useState(1);
  const [changeSubscriptionFlowSteps, setChangeSubscriptionFlowSteps] =
    useState(planId ? 0 : 1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodsProps>();
  const canUnsubscribe =
    planId && !isCancelled && subscriptionStatus === "active";
  const handleNext = async () => {
    setLoading(true);
    const defaultMethod =
      paymentMethods.find((paymentMethod) => paymentMethod.is_default === 1) ||
      paymentMethods[0];
    const paymentDetail = {
      payment_id: defaultMethod?.stripe_method_id,
      plan_id: selectedPlans?.id,
    };

    let res: any;
    if (planId && subscriptionStatus === "active") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res = await changeSubscription(paymentDetail);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res = await actions.postPaymentDetails(paymentDetail);
    }
    const status = get(res, "status", 0);
    const message = get(res, "message", null);
    setLoading(false);
    if (status) {
      toast.dark(message);
      handleChangeSubscriptionFlowSteps(2);
    } else {
      toast.error("Something Went Wrong.");
      onClose();
    }
  };

  const handleAddPaymentFlowSteps = (step: number) => {
    setPaymentFlowSteps(step);
  };

  const handleChangeSubscriptionFlowSteps = (step: number) => {
    setChangeSubscriptionFlowSteps(step);
  };

  useEffect(() => {
    const defaultMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.is_default === 1
    );
    setSelectedPaymentMethod(defaultMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  const handleChangeSubscriptionFlowComponents = () => {
    switch (changeSubscriptionFlowSteps) {
      case 0:
        return (
          <>
            {isOpen ? (
              <UnsubscribeModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSuccess={onClose}
              />
            ) : null}
            <div className={classes.paymentDetails}>
              <Text
                color="#fff"
                size={24}
                className="mb-4"
                fontFamily="milonga"
              >
                Update Subscription
              </Text>
              <Text size={20} color="#fff" className="mb-4">
                Your subscription will update to{" "}
                {selectedPlans.type === "yearly" ? "Annual" : "Monthly"} at the
                end of your current subscription term on:{" "}
                <span style={{ color: "#DEB771" }}>
                  {moment(subscription.valid_till).format(DEFAULT_DATE_FORMAT)}
                </span>
              </Text>
              <Text size={18} color="#fff" className="mb-4">
                By clicking confirm you agree to this change
              </Text>
              <div
                className={cx(classes.button, {
                  [classes.right]: !canUnsubscribe,
                })}
              >
                {canUnsubscribe && (
                  <Button
                    buttonText={
                      <Text color="#fff" size={18} fontWeight="bold">
                        Unsubscribe
                      </Text>
                    }
                    onClick={() => setIsOpen(true)}
                    buttonColor="secondary"
                    variant="link"
                    buttonClassName={classes.cancel}
                  />
                )}
                <Button
                  buttonText={
                    <Text
                      color="#fff"
                      fontWeight="bold"
                      fontFamily="impact"
                      size={20}
                    >
                      Confirm
                    </Text>
                  }
                  buttonColor="secondary"
                  onClick={() => handleChangeSubscriptionFlowSteps(1)}
                  type="button"
                  buttonClassName={classes.confirm}
                />
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className={classes.paymentDetails}>
              <Text
                color="#fff"
                size={40}
                className="mb-4"
                fontFamily="milonga"
              >
                Payment Information
              </Text>
              <Text className="mb-1" color="#fff" size={24}>
                View/Update Payment Information
              </Text>
              <Text className="mb-1" color="#fff" size={12}>
                You may save up to 3 cards
              </Text>
              <div className={classes.paymentWrapper}>
                {paymentMethods.map((paymentMethod, i) => {
                  return (
                    <div
                      onClick={() => setSelectedPaymentMethod(paymentMethod)}
                      key={i}
                      className={cx(classes.existingCard, {
                        [classes.selected]:
                          paymentMethod.id === selectedPaymentMethod?.id,
                      })}
                    >
                      <div>
                        <Text
                          style={{ textTransform: "uppercase" }}
                          fontWeight="semibold"
                          size={18}
                        >
                          {paymentMethod?.brand}{" "}
                          <span style={{ textTransform: "none" }}>
                            {paymentMethod.is_default ? "(Default)" : ""}
                          </span>
                        </Text>
                        <Text size={14}>
                          Credit card ending in {paymentMethod?.last4}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                onClick={() => setAddNewPaymentFlow(true)}
                className={classes.addNew}
              >
                <PlusCircle />
                <Text color="#fff" size={14}>
                  Add a new payment method
                </Text>
              </div>
            </div>

            <div className={classes.button}>
              <Button
                buttonText={
                  <Text
                    color="#fff"
                    fontWeight="bold"
                    fontFamily="impact"
                    size={36}
                  >
                    Next
                  </Text>
                }
                buttonColor="secondary"
                onClick={handleNext}
                disabled={!selectedPaymentMethod}
                loading={loading}
                type="submit"
                buttonClassName={classes.submit}
              />
            </div>
          </>
        );
      case 2:
        return <Complete onClose={onClose} planId={planId} />;
      default:
        break;
    }
  };

  const getAddPaymentFlowComponents = () => {
    switch (paymentFlowSteps) {
      case 1:
        return (
          <div className={classes.paymentFormWrapper}>
            <PaymentForm
              hideDefaultCheckbox
              handlePayment={(paymentInfo: PaymentHandleProps) => {
                actions.addPaymentMethods({
                  payment_method_id: paymentInfo?.id,
                  is_default: false,
                });
                const paymentDetail = {
                  payment_id: paymentInfo?.id,
                  name_on_card: paymentInfo.name_on_card,
                  last4: paymentInfo.card?.last4,
                  plan_id: selectedPlans?.id,
                };
                setPaymentDetails(paymentDetail as NewPaymentDetailsProps);
                handleAddPaymentFlowSteps(2);
              }}
            />
          </div>
        );
      case 2:
        return (
          <Confirm
            handleAddPaymentFlowSteps={handleAddPaymentFlowSteps}
            paymentDetails={paymentDetails}
            selectedPlans={selectedPlans}
          />
        );
      case 3:
        return <Complete onClose={onClose} planId={planId} />;
      default:
        break;
    }
  };

  return (
    <div className={classes.wrapper}>
      {addNewPaymentFlow ? (
        <div className={classes.addPaymentWrapper}>
          {paymentFlowSteps === 1 && (
            <>
              <Text
                color="#fff"
                size={40}
                className="mb-4 text-center"
                fontFamily="milonga"
              >
                Payment Information
              </Text>
              <Text className="mb-1 text-center" color="#fff" size={24}>
                Enter your debit or credit information
              </Text>
            </>
          )}
          {getAddPaymentFlowComponents()}
        </div>
      ) : (
        handleChangeSubscriptionFlowComponents()
      )}
    </div>
  );
};

export default PaymentInformation;
